<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des comptes</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-primary mr-1"  @click="openDetail(null)">
            <i class="icon-md fas fa-plus"></i>
          </a>
          <a class="btn btn-icon btn-sm btn-danger mr-1"  v-b-modal.search>
            <i class="icon-md fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="1">
            <select class="form-control" v-model="datatable.limitPage" @change="datatableInit">
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </b-col>
        </b-row>
        <b-table responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
          <template #cell(url)="data">
            <a target="_blank" :href="data.value">{{data.value}}</a>
            <b-button variant="primary" size="sm" class="btn-icon ml-2 float-right" v-clipboard:copy="data.value"><i class="icon-md fas fa-copy"></i></b-button>
          </template>
          <template #cell(username)="data">
            <span>{{data.value}}</span>
            <b-button variant="primary" size="sm" class="btn-icon ml-2 float-right" v-clipboard:copy="data.value"><i class="icon-md fas fa-copy"></i></b-button>
          </template>
          <template #cell(password)="data">
            <span>{{data.value}}</span>
            <b-button variant="primary" size="sm" class="btn-icon ml-2 float-right" v-clipboard:copy="data.value"><i class="icon-md fas fa-copy"></i></b-button>
          </template>
          <template #cell(date_create)="data">
            {{ $store.back.timestampToDate(data.value) }}
          </template>
          <template #cell(_id)="data">
            <a class="btn btn-icon btn-sm btn-danger mr-1"  @click="openDetail(data.item)">
              <i class="icon-md fas fa-eye"></i>
            </a>
          </template>
        </b-table>
        <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      </div>
    </div>
    <b-modal id="search" title="Recherche" size="xl" ref="myModalDeleteRef">
      <div class="row">
        <div class="form-group col-md-4" v-for="(field,i) in fields.filter(f => f.searchable == true)" :key="i">
          <label>{{field.label}}</label>
          <input type="text" :placeholder="'Rechercher par '+field.label" :data-column="field.key" class="form-control">
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="searchInit">
            Recherche
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.myModalDeleteRef.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="detail" title="Detail de sms" size="xl" ref="myModalDetail">
      <div class="row">
        <div class="form-group col-md-3">
          <label>ID</label>
          <input type="text" v-model="detail.id" class="form-control" readonly>
        </div>
        <div class="form-group col-md-3">
          <label>URL</label>
          <input type="text" v-model="detail.url" class="form-control">
        </div>
        <div class="form-group col-md-3">
          <label>Nom d'utilisateur</label>
          <input type="text" v-model="detail.username" class="form-control">
        </div>
        <div class="form-group col-md-3">
          <label>Mot de passe</label>
          <input type="text" v-model="detail.password" class="form-control">
        </div>
        <div class="form-group col-md-12">
          <label>Commentaire</label>
          <b-form-textarea id="textarea" v-model="detail.commentaire" rows="3" max-rows="6"></b-form-textarea>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="danger" class="float-right mr-2" @click="$refs.myModalDetail.hide();">
            Fermer
          </b-button>
          <b-button variant="primary" class="float-right mr-2" @click="saveDetail()">
            Sauvegarder
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  data(){
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: '#',
          sortable:true,
          searchable:true
        },
        {
          key: 'commentaire',
          label: 'Titre',
          sortable:false,
          searchable:true
        },
        {
          key: 'url',
          label: `URL`,
          sortable:false,
          searchable:false
        },
        {
          key: 'username',
          label: `Nom d'utilisateur`,
          sortable:false,
          searchable:false
        },
        {
          key: 'password',
          label: 'Mot de passe',
          sortable:false,
          searchable:true
        },
        {
          key: 'date_create',
          label: 'Date Creation',
          sortable:false,
          searchable:false
        },
        {
          key: '_id',
          label: '',
          sortable:false,
          searchable:false
        },
      ],
      datatable:{
        currentPage: 1,
        nextPage: 2,
        previousPage: 0,
        limitPage: 20,
        totalRow: 0,
        data: []
      },
      sort:{
        field: 'id',
        value: 'DESC'
      },
      searchQuery:null,
      detail:{
        id:null,
        url:null,
        username:null,
        password:null,
        commentaire:null,
        date_create:null,
        date_update:null,
        status:1
      }
    };
  },
  components: {
  },
  watch: {
  },
  beforeMount() {
  },
  methods: {
    datatableInit: function(){
      var the = this;
      this.$store.back.ajax('/magic/liste?module=MDP&'+this.query,null,(res) => {
        the.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    searchInit(){
      this.datatable.currentPage = 1;
      let query = {};
      let inputs  = document.querySelectorAll('[data-column]');
      inputs.forEach(elt => {
        let value  = elt.value;
        let key = elt.dataset.column;
        query[key] = value
      });
      if(Object.entries(query).length > 0){
        this.searchQuery = encodeURI(JSON.stringify(query));
      }else{
        this.searchQuery = null;
      }
      this.$refs.myModalDeleteRef.hide();
      this.datatableInit();
    },
    openDetail(obj){
      if(obj === null){
        this.detail = {
          id:null,
          url:null,
          username:null,
          password:null,
          commentaire:null,
          date_create:null,
          date_update:null,
          status:1
        };
      }else{
        this.detail = obj;
      }
      this.$refs.myModalDetail.show();
    },
    saveDetail(){
      if(this.detail.id === null){
        this.$store.back.ajax('/magic/create?module=MDP',this.detail, (res) => {
          if(res.status === true){
            this.$bvToast.toast(`Votre parametrage est bien sauvegarde !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
          }
        });
      }else{
        this.$store.back.ajax('/magic/update?module=MDP', this.detail, (res) => {
          if(res.status === true){
            this.$bvToast.toast(`Votre parametrage est bien sauvegarde !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
          }
        });
      }
      this.detail = {
        id:null,
        url:null,
        username:null,
        password:null,
        commentaire:null,
        date_create:null,
        date_update:null,
        status:1
      };
      this.$refs.myModalDetail.hide();
      this.datatableInit();
    }
  },
  computed: {
    query: function(){
      var sort = this.sort.field === null ? null : encodeURI(JSON.stringify(this.sort));
      return 'page='+this.datatable.currentPage+'&limit='+this.datatable.limitPage+'&sort='+sort+'&search='+this.searchQuery;
    }
  },
  mounted() {
    this.datatableInit();
  }
};
</script>
